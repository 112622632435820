.header {
  padding-top: rem(10);
  padding-bottom: rem(10);
  position: fixed;
  width: 100%;
  z-index: 999;
  background-color: white;
  @include media-breakpoint-up(lg) {
    padding-top: rem(10);
    padding-bottom: rem(10);
  }

  &__logo {
    display: block;
    width: 100%;
    max-width: rem(142);
    @include media-breakpoint-up(xxl) {
      max-width: rem(242);
    }

    img {
      width: 100%;
    }
  }

  .row {
    --bs-gutter-x: #{rem(20)};
  }

  &__menu-col {
    @include media-breakpoint-down(xl) {
      padding: 0;
      margin: 0;
    }
  }

  nav {
    display: none;
    @include media-breakpoint-up(xl) {
      display: block;
    }

    ul {
      list-style-type: none;
      margin-bottom: 0;
      display: flex;
      justify-content: center;
      padding-left: 0;
      --bs-gutter-x: #{rem(30)};
      margin-left: calc(var(--bs-gutter-x) * -0.5);
      margin-right: calc(var(--bs-gutter-x) * -0.5);
      @include media-breakpoint-up(xxl) {
        --bs-gutter-x: #{rem(60)};
      }

      li.active {
        a {
          color: #00abab;
        }
        border-bottom: 1px solid #00abab;
      }

      a {
        padding-left: calc(var(--bs-gutter-x) * 0.5);
        padding-right: calc(var(--bs-gutter-x) * 0.5);
        font-size: rem(16);
        font-weight: 600;
        @include media-breakpoint-up(xxl) {
          font-size: rem(18);
        }
      }
    }
  }

  &__search {
    --bs-btn-padding-x: 11px;
    --bs-btn-padding-y: 11px;
    --bs-btn-border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include media-breakpoint-up(lg) {
      --bs-btn-padding-x: 15px;
      --bs-btn-padding-y: 15px;
      --bs-btn-border-radius: 16px;
    }

    svg {
      font-size: 18px;
      width: 1em;
      height: 1em;
    }
  }

  &__btnsGroup {
    display: flex;
    border: 1px solid $gray-500;
    border-radius: 12px;
    @include media-breakpoint-up(lg) {
      border-radius: 16px;
    }

    button {
      --bs-btn-font-size: 14px;
      --bs-btn-color: #000;
      --bs-btn-hover-color: #{$primary};
      --bs-btn-hover-bg: #fff;
      --bs-btn-active-color: #000;
      --bs-btn-active-bg: #fff;
      --bs-btn-disabled-color: #000;
      --bs-btn-disabled-bg: transparent;
      border: none;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 6px;
      padding-right: 6px;
      @include media-breakpoint-up(lg) {
        --bs-btn-font-size: 16px;
        padding-top: 12px;
        padding-bottom: 12px;
      }
      @include media-breakpoint-up(xxl) {
        padding-top: 13px;
        padding-bottom: 13px;
      }

      &:hover {
        svg {
          path[fill='black'] {
            fill: $primary;
          }
        }
      }

      &:first-child {
        padding-left: 20px;
        @include media-breakpoint-up(xxl) {
          padding-left: 40px;
        }
      }

      &:last-child {
        padding-right: 20px;
        @include media-breakpoint-up(xxl) {
          padding-right: 40px;
        }
      }

      svg {
        font-size: 24px;
        width: 1em;
        height: 1em;

        path {
          transition: all 0.36s ease-in-out;
        }
      }
    }
  }

  .menu-wrapper {
    position: relative;

  }

  .menu {
    background-color: #fff;
    border-radius: 5px;
    position: absolute;
    width: 150px;
    left: -100px;
    padding: 10px;
    border: 1px solid #00000066
  }

  .menu-toggle {
    transition: all 0.36s ease-in-out;
    width: rem(36);
    height: rem(36);
    background-color: $primary;
    border-radius: 6px;
    position: relative;
    cursor: pointer;
    @include media-breakpoint-up(sm) {
      width: 42px;
      height: 42px;
      border-radius: 12px;
    }
    @include media-breakpoint-up(lg) {
      width: rem(50);
      height: rem(50);
    }

    &:before,
    &:after {
      transition: all 0.36s ease-in-out;
      content: '';
      display: block;
      position: absolute;
      width: rem(20);
      height: 2px;
      background-color: #000;
      top: 50%;
      left: 50%;
      border: none;
      @include media-breakpoint-up(sm) {
        width: rem(22);
      }
    }

    &:before {
      transform: translate(-50%, calc(-50% - 4px));
    }

    &:after {
      transform: translate(-50%, calc(-50% + 4px));
    }

    &:hover {
      background-color: #00b7b7;
    }

    &.active {
      &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }

      &:before {
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }

  }

  .mob-menu {
    transition: all 0.36s ease-in-out;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    background-color: #fff;
    border-radius: 0 0 rem(16) rem(16);
    padding: rem(22);
    z-index: 1000;
    transform: translateY(-100vh);
    pointer-events: none;

    &.active {
      transform: translateY(0);
      pointer-events: all;
    }

    .header__btnsGroup {
      justify-content: center;

      a {
        display: block;
        width: 100%;
      }
    }
  }
}

.qr_lk {
  cursor: pointer;

  svg {
    path.black {
      fill: black;
    }
  }

  &:hover {
    svg {
      path.black {
        fill: rgb(0, 171, 171);
      }
    }
  }
}

.header-modal {
  .modal-content {
    margin: auto;
    width: 355px;
  }

  .modal-header {
    padding: 3rem;

    button[aria-label="Close"] {
      top: 3rem
    }
  }
}
