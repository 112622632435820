.modal {
  --bs-modal-width: #{rem(626)};
  --bs-modal-padding: #{rem(40)};
  --bs-modal-border-radius: #{rem(24)};
  &-header {
    padding: rem(24) rem(16) rem(16) rem(16);
    flex-direction: column;
    align-items: flex-start;
    border-bottom: none;
    @include media-breakpoint-up(md) {
      padding: rem(40) rem(40) rem(20) rem(40);
    }
  }
  &-title {
    font-size: rem(20);
    margin-bottom: rem(8);
    padding-right: rem(16);
    @include media-breakpoint-up(sm) {
      font-size: rem(24);
    }
    @include media-breakpoint-up(md) {
      font-size: rem(28);
      padding-right: rem(24);
    }
  }
  &-body {
    padding: rem(16) rem(16) rem(16) rem(16);
    @include media-breakpoint-up(md) {
      padding: rem(20) rem(40) rem(20) rem(40);
    }
  }
  &-footer {
    padding: rem(16) rem(16) rem(24) rem(16);
    border-top: none;
    justify-content: flex-start;
    @include media-breakpoint-up(md) {
      padding: rem(20) rem(40) rem(40) rem(40);
    }
    button {
      @include media-breakpoint-up(lg) {
        --bs-btn-font-size: #{rem(18)};
        --bs-btn-padding-y: #{rem(15.5)};
      }
    }
  }
  &__date {
    font-size: rem(12);
    color: rgba($color: #000000, $alpha: 0.5);
    @include media-breakpoint-up(sm) {
      font-size: rem(14);
    }
    @include media-breakpoint-up(md) {
      font-size: rem(16);
    }
  }
  .nav {
    ul {
      margin-bottom: 0;
      padding-left: 0;
      list-style-type: none;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: rem(24);
      li {
        transition: all 0.36s ease-in-out;
        font-weight: 600;
        font-size: rem(16);
        color: rgba($color: #000000, $alpha: 0.5);
        &:not(:last-child) {
          margin-right: rem(24);
        }
        &.active {
          color: #000;
        }
      }
    }
    &-tabs {
      margin-bottom: rem(24);
      border-bottom: none;
    }
    &__item {
      display: none;
      width: 100%;
      &.active {
        display: block;
      }
      button {
        padding: 0;
        font-size: rem(16);
        font-weight: 600;
        border: none;
        padding-right: rem(24);
        color: rgba($color: #000000, $alpha: 0.5);
        &:hover,
        &.active {
          color: #000;
        }
      }
    }
  }
  .form-wrap {
    &__input-wrap {
      &:not(:last-child) {
        margin-bottom: rem(32);
      }
    }
    &__title {
      font-size: rem(14);
      color: rgba($color: #000000, $alpha: 0.4);
      margin-bottom: rem(12);
    }
  }
  .form-select {
    background-color: #fff;
    border: 1px solid $gray-500;
    border-radius: rem(16);
    padding: rem(16.5) rem(39) rem(16.5) rem(23);
    font-weight: 600;
    --bs-form-select-bg-img: url("data:image/svg+xml,%3Csvg width='18' height='11' viewBox='0 0 18 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17 1L9 9L1 1' stroke='%2300BFBF' stroke-width='2'/%3E%3C/svg%3E%0A");
    background-size: 16px 8px;
    background-position: calc(100% - rem(23)) center;
    &:focus {
      background-color: #fff;
    }
  }
  .price {
    font-size: rem(24);
    margin: 0 0 rem(18) 0;
    font-weight: 400;
    span {
      font-weight: 700;
    }
  }
  .redirect {
    font-size: rem(12);
    font-weight: 500;
    color: rgba($color: #000000, $alpha: 0.5);
    margin: 0;
  }
  .btn-close {
    width: rem(16);
    height: rem(16);
    background-color: transparent;
    border: none;
    outline: none;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 25L25 0.999999' stroke='black' stroke-width='1.5' stroke-linecap='round'/%3E%3Cpath d='M25 25L1.00003 0.999999' stroke='black' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    top: rem(24);
    right: rem(16);
    @include media-breakpoint-up(md) {
      top: rem(80);
      right: rem(52);
      width: rem(24);
      height: rem(24);
    }
  }

  &__back {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    margin-top: rem(32);
    color: rgba(0, 0, 0, 0.4);
    font-weight: 500;
    &::before {
      content: url("data:image/svg+xml,%3Csvg width='11' height='19' viewBox='0 0 11 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 17.5L2 9.5L10 1.5' stroke='%2300BFBF' stroke-width='2'/%3E%3C/svg%3E%0A");
      margin-right: rem(24);
      position: relative;
      top: rem(4);
      transition: all 0.36s ease-in-out;
    }
    transition: all 0.36s ease-in-out;
    &:hover {
      color: #000;
      &::before {
        transform: translateX(-8px);
      }
    }
  }
}
