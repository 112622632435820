.event-card {
  color: $body-color;

  &:hover {
    img {
      transform: scale(1.1);
    }
  }

  &__img-wrap {
    aspect-ratio: 467/265;
    border-radius: rem(16);
    width: 100%;
    // max-width: rem(467);
    margin-bottom: rem(24);
    overflow: hidden;
    position: relative;
    background-color: rgb(0 0 0 / 37%);
    @include media-breakpoint-up(md) {
      border-radius: rem(24);
    }
    @include media-breakpoint-up(lg) {
      border-radius: rem(32);
    }
    &_mask {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0px;
      top: 0;
      background: linear-gradient(230.47deg, rgba(17, 0, 83, 0) 43.17%, rgba(0, 0, 0, 0.56) 81.31%);
      transform: matrix(1, 0, 0, -1, 0, 0);
      z-index: 1;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all 0.36s ease-in-out;
    }
  }

  &__date {
    z-index: 2;
    font-family: 'ActayWide-Bold';
    position: absolute;
    top: rem(16);
    left: rem(16);
    font-size: rem(16);
    line-height: 1.2;
    color: #fff;
    @include media-breakpoint-up(md) {
      left: rem(24);
      font-size: rem(18);
    }
    @include media-breakpoint-up(xxl) {
      top: rem(27);
      left: rem(40);
      font-size: rem(24);
    }
  }

  .cut_h4 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: calc(1.2em * 3);
  }

  .h4 {
    line-height: 1.2;
    margin-bottom: rem(12);
    font-size: rem(20);

    @include media-breakpoint-up(md) {
      margin-bottom: rem(16);
      font-size: rem(24);
    }
    @include media-breakpoint-up(xxl) {
      margin-bottom: rem(24);
      font-size: rem(32);
    }
  }

  &__info {
    font-size: rem(14);
    font-weight: 400;
    line-height: 1.62;
    @include media-breakpoint-up(md) {
      font-size: rem(16);
    }

    span {
      font-weight: 700;
      margin-right: rem(15);
    }
  }

  &__content {
    .event-card__date {
      position: static;
      color: $primary;
      font-size: rem(24);
      margin-bottom: rem(16);
      display: none;
      @include media-breakpoint-up(xxl) {
        font-size: rem(24);
        margin-bottom: rem(32);
      }
    }
  }
}

.isEvents {
  display: grid;
  grid-template-rows: 1fr 1fr;
  height: 100%;
  .event-card__content {
    display: grid;
    grid-template-rows: 1fr 0.5fr;
  }
}

.events_bg_image {
  position: absolute;
  top: 0;
  right: 0;
  height: 60vh;
  max-height: 680px;
}
