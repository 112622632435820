$gray-color: #F1F0F5;
$blue-color: #220A80;

  .userSidebar {
    height: fit-content;
    background-color: $blue-color;
    border-radius: 0 30px 30px 0;
    color: #fff;
    display: grid;
    grid-template-rows: 285px 1fr 155px;
    @media (max-width: 575px) {
      grid-template-rows: 165px 1fr 155px;
    }
    &_links {
      cursor: pointer;
      display: flex;
      column-gap: 10px;
      align-items: center;
      color: white;
      height: 72px;
      &.active {
        background-color: $gray-color;
        color: $blue-color;
        svg {
          path {
            color: $blue-color;
            stroke: $blue-color;
          }
        }
      }
    }
  }

  .userPage {
    background-color: $gray-color ;
    .form-wrap__input {
      background-color: white;
    }
    .form-label {
      position: absolute;
      right: 20px;
      top: 30px;
      width: 100%;
      display: inline-flex;
      justify-content: end;
      align-items: center;
      height: 59px;
    }
  }

  .menu-accordion {
    --bs-accordion-bg: transparent;
    .accordion-item {
      border: none;
    }
    .accordion-body {
      width: calc(100% + 12px);
      padding: 0;
    }
    .accordion-header {
      button {
        background-color: transparent;
        color: #fff;
        outline: none;
        &:focus {
          box-shadow: none;
        }
        &::after {
          background-image: url("/img/accordion_btn.svg");
        }
      }
    }

  }


  h3 {
    font-size: rem(32);
  }


  .user-table {
    margin-top: 20px;
    margin-bottom: 20px;
    &-head {
      margin-bottom: 20px;
      &-title {
        font-family: $font-family-sans-serif;
        font-size: rem(14);
        font-weight: 400;
        line-height: 12px;
        text-align: left;
      }
    }
    &-row {
      padding-left: calc(var(--bs-gutter-x)* 0.5);
      padding-right: calc(var(--bs-gutter-x)* 0.5);
    }
    &-body {
      display: grid;
      row-gap: 20px;

      &-item {
        width: 100%;
        padding: 16px 24px;
        background-color: white;
        border-radius: 16px ;
        &-column {
          display: flex;
          column-gap: 10px;
          align-items: center;
          &:first-child {
            display: grid;
            grid-template-columns: 64px 1fr;
          }
          .avatar {
            display: block;
            background-origin: border-box;
            background-repeat: no-repeat;
            width: 64px;
            height: 64px;
            background-position: center;
            background-size: 64px 64px;
            background-color: $gray-color;
            border-radius: 10px;
          }
          .name-section {
            display: grid;
            h2 {
              font-family: $font-family-sans-serif;
              font-size: 24px;
              font-weight: 600;
              line-height: 28.8px;
              text-align: left;

            }
            p{
              font-family: $font-family-sans-serif;
              font-size: 12px;
              font-weight: 500;
              line-height: 16.8px;
              text-align: left;

            }

          }
          span {
            font-family: $font-family-sans-serif;
            font-size: 16px;
            font-weight: 500;
            line-height: 19.5px;
            text-align: left;
          }
        }

      }
    }
  }

  .form-wrap__title {
    opacity: .5;
  }