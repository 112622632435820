.sSteps {
  display: flex;
  align-items: flex-end;
  position: relative;
  border-radius: rem(20) rem(20) 0 0;
  z-index: 1;
  overflow: hidden;
  min-height: rem(748);
  padding: rem(60) 0 rem(100) 0;
  margin-bottom: rem(-71);
  @include media-breakpoint-up(md) {
    border-radius: rem(40) rem(40) 0 0;
    min-height: rem(840);
    padding: rem(80) 0 rem(100) 0;
  }
  @include media-breakpoint-up(xxl) {
    border-radius: rem(80) rem(80) 0 0;
    min-height: rem(986);
    padding: rem(80) 0 rem(260) 0;
    margin-bottom: rem(-170);
  }
  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.19), rgba(0, 0, 0, 0.19)),
      linear-gradient(26.09deg, rgba(0, 0, 0, 0.62) 15.35%, rgba(0, 0, 0, 0.14) 63.46%);
  }
  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -2;
  }
  h2 {
    color: #fff;
    max-width: rem(280);
    font-size: rem(34);
    margin-bottom: rem(32);
    letter-spacing: -0.23rem;
    @include media-breakpoint-up(md) {
      position: relative;
      font-size: rem(50);
      margin-bottom: rem(40);
      max-width: 100%;
      padding-right: rem(100);
    }
    @include media-breakpoint-up(xl) {
      font-size: rem(90);
      margin-bottom: rem(66);
      // max-width: rem(962);
    }
    &:after {
      content: '';
      position: absolute;
      display: block;
      width: rem(64);
      height: rem(64);
      @include media-breakpoint-down(md) {
        top: rem(243);
        left: 50%;
        transform: translateX(-50%);
      }
      @include media-breakpoint-up(md) {
        right: 0;
        top: 0;
        margin-left: auto;
        width: rem(72);
        height: rem(72);
      }
      @include media-breakpoint-up(xxl) {
        width: rem(122);
        height: rem(122);
      }

      //background-image: url("data:image/svg+xml,%3Csvg width='122' height='122' viewBox='0 0 122 122' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='60.979' cy='60.979' r='60.229' stroke='white' stroke-width='1.5'/%3E%3Cpath d='M80.5547 60.4211L50.3449 77.8627L50.3449 42.9795L80.5547 60.4211Z' fill='white'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }
  &__slider {
    --swiperCount: 1;
    --swipergutter: #{rem(20)};
    margin-right: calc(var(--swipergutter) * -1) !important;
    overflow: visible !important;
    @include media-breakpoint-up(md) {
      --swiperCount: 2;
      --swipergutter: #{rem(32)};
    }
    @include media-breakpoint-up(xxl) {
      --swiperCount: 3;
      --swipergutter: #{rem(59)};
    }
  }
  &__slide {
    display: flex !important;
    flex-direction: column-reverse;
    width: calc(84% / var(--swiperCount) - var(--swipergutter)) !important;
    height: auto !important;
    margin-right: var(--swipergutter);
    background-color: #fff;
    padding: rem(20);
    border-radius: rem(12);
    @include media-breakpoint-up(md) {
      width: calc(100% / var(--swiperCount) - var(--swipergutter)) !important;
      padding: rem(20);
      border-radius: rem(16);
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    @include media-breakpoint-up(xxl) {
      padding: rem(30);
      border-radius: rem(32);
    }
    .h6 {
      font-size: rem(14);
      margin-bottom: 0;
      max-width: rem(165);
      margin-top: rem(8);
      @include media-breakpoint-up(md) {
        font-size: rem(16);
        max-width: rem(195);
        margin-top: 0;
      }
      @include media-breakpoint-up(xxl) {
        font-size: rem(26);
        max-width: rem(308);
      }
    }
    span {
      font-family: $headings-font-family;
      font-size: rem(36);
      line-height: 1;
      margin-bottom: auto;
      @include media-breakpoint-up(md) {
        font-size: rem(44);
        margin-bottom: 0;
      }
      @include media-breakpoint-up(xxl) {
        font-size: rem(55);
      }
    }
  }
}
