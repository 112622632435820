.sAuth {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
  .container {
    display: flex;
    flex-direction: column;
    // flex-grow: 1;
  }
  &__wrap {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    @include media-breakpoint-up(lg) {
      flex-direction: row;
    }
  }
  &__side {
    flex-grow: 1;
    width: 100%;
    padding: rem(48) 0;
    @include media-breakpoint-up(sm) {
      padding: rem(56) 0;
    }
    @include media-breakpoint-up(lg) {
      padding: rem(65) 0;
    }
    &--left {
      @include media-breakpoint-up(lg) {
        padding-right: rem(40);
      }
      @include media-breakpoint-down(lg) {
        display: flex;
        // align-items: center;
      }
    }
    &--right {
      position: relative;
      // background-color: $gray-900;
      z-index: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding-left: rem(79);
      height: calc(100vh - 150px);
      min-height: 460px;
      @include media-breakpoint-down(lg) {
        min-height: rem(485);
        padding-left: 0;
      }
      &_caption{
        position: absolute;
        bottom: 65px;
      }
      .bg {
        display: block;
        // max-width: 100%;
        height: 100%;
        // margin-bottom: rem(47);
        background-color: #fff;
        position: absolute;
        top: 0;
        left: calc((100vw - 100%) / -2);
        width: 100vw;
        object-fit: cover;
        z-index: -1;
        border-radius: rem(60) rem(60) 0 0;
        @include media-breakpoint-up(lg) {
          left: 0;
          width: calc(100% + (100vw - 200%) / 2 + 20px);
          border-radius: rem(60) 0 0 rem(60);
        }
      }
      .h3 {
        font-size: rem(14);
        margin-bottom: 0;
        color: #fff;
        text-transform: uppercase;
        margin-bottom: rem(32);
        @include media-breakpoint-up(md) {
          font-size: rem(18);
        }
        @include media-breakpoint-up(xxl) {
          font-size: rem(24);
          margin-bottom: rem(64);
        }
        span {
          display: block;
          @include rfs(72px);
        }
      }
      ul {
        margin-bottom: 0;
        padding-left: 0;
        list-style-type: none;
        li {
          font-size: rem(14);
          color: #fff;
          padding-left: rem(40);
          position: relative;
          @include media-breakpoint-up(md) {
            font-size: rem(16);
          }
          @include media-breakpoint-up(lg) {
            padding-left: rem(57);
            font-size: rem(20);
          }
          &:not(:last-child) {
            margin-bottom: rem(24);
          }
          &:before {
            content: "";
            position: absolute;
            display: block;
            top: rem(3);
            left: 0;
            width: rem(24);
            height: rem(19);
            @include media-breakpoint-up(lg) {
              width: rem(34);
              height: rem(27);
            }

            background-image: url("data:image/svg+xml,%3Csvg width='34' height='27' viewBox='0 0 34 27' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.2216 26.5128L0 13.2912L6.64559 6.6456L13.2216 13.2216L26.4432 0L33.0888 6.6456L13.2216 26.5128Z' fill='%2300BFBF'/%3E%3C/svg%3E%0A");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
          }
        }
      }
    }
  }
  &__content {
    width: 100%;
  }
  h1 {
    margin-bottom: rem(16);
    line-height: 1.19;
    font-size: rem(26);
    @include media-breakpoint-up(sm) {
      font-size: rem(32);
    }
    @include media-breakpoint-up(lg) {
      font-size: rem(42);
    }
  }
  p {
    font-size: rem(14);
    font-weight: 400;
    max-width: rem(400);
    @include media-breakpoint-up(lg) {
      font-size: rem(16);
    }
    // margin-bottom: rem(40);
    // @include media-breakpoint-up(lg) {
    //   margin-bottom: rem(52);
    // }
    a {
      text-decoration: underline;
    }
  }
  .form-wrap {
    @include media-breakpoint-up(lg) {
      max-width: rem(508);
      margin-bottom: rem(39);
    }
    &__row {
      --bs-gutter-x: #{rem(16)};
      --bs-gutter-y: #{rem(16)};
    }
    &__title {
      a {
        font-size: rem(12);
        text-decoration: underline;
        margin-left: auto;
        font-weight: 400;
      }
    }
  }
  &__other-way {
    display: none;
    position: relative;
    max-width: rem(508);
    text-align: center;
    margin-bottom: rem(0);
    @include media-breakpoint-up(sm) {
      display: flex;
      align-items: center;
      margin: 0 rem(16);
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: rem(39);
      justify-content: center;
      margin: 0;
    }
    &:before {
      @include media-breakpoint-up(sm) {
        content: "";
        position: absolute;
        display: block;
        background-color: $gray-500;
        z-index: -1;
        height: 100%;
        width: 1px;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
      }
      @include media-breakpoint-up(lg) {
        top: 50%;
        left: 0;
        width: 100%;
        height: 1px;
        transform: translateY(-50%);
      }
    }
    span {
      background-color: #fff;
      padding: rem(16) 0;
      font-size: rem(12);
      z-index: 1;
      text-transform: uppercase;
      @include media-breakpoint-up(lg) {
        font-size: rem(16);
        padding: 0 rem(22);
      }
    }
  }
  &__qr {
    display: none;
    width: 100%;
    margin: auto 0;
    @include media-breakpoint-up(sm) {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
    }
    @include media-breakpoint-down(md) {
      svg {
        width: 100%;
      }
    }
    @include media-breakpoint-up(lg) {
      // align-items: center;
      flex-direction: row;
      margin: 0;
    }
    img {
      flex: 0 0 auto;
      width: 100%;
      max-width: rem(173);
      height: auto;
      @include media-breakpoint-up(sm) {
        max-width: rem(189);
      }
      @include media-breakpoint-up(lg) {
        margin-right: rem(34);
      }
    }
    span {
      font-size: rem(12);
      max-width: rem(320);
      text-align: center;
      @include media-breakpoint-up(md) {
        font-size: rem(14);
        max-width: rem(213);
      }
      @include media-breakpoint-up(lg) {
        text-align: left;
      }
    }
  }
  &__success {
    display: flex;
    align-items: center;
    font-weight: 400;
    max-width: rem(467);
    p {
      font-size: rem(14);
      margin-bottom: 0;
      max-width: rem(247);
      @include media-breakpoint-up(lg) {
        max-width: none;
        font-size: rem(16);
      }
      a {
        font-weight: 700;
        text-decoration: none;
      }
    }
    svg {
      flex: 0 0 auto;
      margin-right: rem(24);
    }
  }
}
