.sBeMember {
  overflow: hidden;
  padding-top: rem(40);
  padding-bottom: rem(40);
  @include media-breakpoint-up(md) {
    padding-top: rem(60);
    padding-bottom: rem(111);
  }
  @include media-breakpoint-up(xxl) {
    padding-top: rem(165);
    padding-bottom: rem(113);
  }
  &__wrap {
    position: relative;
    padding: rem(60) rem(20);
    background-color: $primary;
    border-radius: rem(20);
    z-index: 1;
    overflow: hidden;
    @include media-breakpoint-up(md) {
      padding: rem(50) rem(50);
      border-radius: rem(40);
    }
    @include media-breakpoint-up(xxl) {
      padding: rem(130) rem(43);
      border-radius: rem(80);
    }
    h2 {
      font-size: rem(36);
      margin-bottom: rem(30);
      margin-left: auto;
      line-height: 1;
      color: #0c0843;
      letter-spacing: -0.23rem;
      @include media-breakpoint-up(md) {
        font-size: rem(50);
        margin-bottom: rem(24);
        max-width: rem(338);
      }
      @include media-breakpoint-up(xl) {
        font-size: rem(90);
        margin-bottom: rem(40);
        max-width: rem(687);
      }
    }
  }
  &__content {
    @include media-breakpoint-up(md) {
      max-width: rem(266);
      margin-left: auto;
    }
    @include media-breakpoint-up(xl) {
      margin-right: rem(90);
      max-width: rem(522);
    }
    @include media-breakpoint-up(xxl) {
      max-width: rem(467);
    }
    p {
      font-size: rem(14);
      line-height: 1.6;
      @include media-breakpoint-up(md) {
        font-size: rem(16);
      }
      @include media-breakpoint-up(xl) {
        font-size: rem(20);
        margin-bottom: rem(61);
      }
    }
    button {
      @include media-breakpoint-up(xl) {
        --bs-btn-padding-y: #{rem(17)};
      }
      width: 100%;
      max-width: rem(299);
    }
  }
  &__bg {
    display: block;
    margin: 0 auto;
    width: 100%;
    height: auto;
    max-width: rem(308);
    z-index: 2;
    @include media-breakpoint-down(md) {
      transform: translateY(rem(60));
    }
    @include media-breakpoint-up(md) {
      position: absolute;
      left: rem(30);
      bottom: 0;
      max-width: rem(244);
    }
    @include media-breakpoint-up(lg) {
      max-width: rem(308);
      left: rem(77.6);
    }
    @include media-breakpoint-up(xxl) {
      max-width: rem(628);
    }
  }
  &__bg-2 {
    display: none;
    @include media-breakpoint-up(md) {
      position: absolute;
      width: 100%;
      max-width: rem(291);
      height: auto;
      right: -75px;
      bottom: -136px;
      z-index: -1;
      display: block;
    }
    @include media-breakpoint-up(xl) {
      right: 28px;
      bottom: -64px;
    }
  }
  &__bg-3 {
    position: absolute;
    z-index: -1;
    height: auto;
    top: 223px;
    left: -200px;
    @include media-breakpoint-up(md) {
      width: 100%;
      max-width: rem(1030);
      max-width: rem(486);
      top: 33px;
      left: 0;
    }
    @include media-breakpoint-up(xxl) {
      max-width: rem(1030);
      top: 83px;
    }
  }
}
