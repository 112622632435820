.sEventReg {
  padding: rem(20);
  background-color: var(--sEventRegBg, $primary);
  border-radius: rem(20);
  border: 1px solid var(--sEventRegBorderColor, transparent);
  z-index: 999;
  @include media-breakpoint-down(sm) {
    margin-left: calc(var(--bs-gutter-x) * -1);
    margin-right: calc(var(--bs-gutter-x) * -1);
    padding-bottom: rem(52);
    margin-bottom: rem(-28);
  }
  @include media-breakpoint-up(lg) {
    max-width: rem(390);
  }
  @include media-breakpoint-up(xl) {
    padding: rem(36) rem(40) rem(40);
    border-radius: rem(40);
  }
  &__date {
    line-height: 1.1;
    font-size: rem(26);
    margin-bottom: rem(8);
    padding-top: rem(60);
    @include media-breakpoint-up(md) {
      display: flex;
      align-items: center;
      padding-top: rem(60);
      font-size: rem(32);
      margin-bottom: rem(24);
    }
    @include media-breakpoint-up(lg) {
      display: block;
      padding-top: 0;
    }
    @include media-breakpoint-up(xl) {
      padding-top: 0;
      margin-bottom: rem(40);
      line-height: 1.4;
    }
    span {
      margin-top: rem(8);
      display: block;
      font-size: rem(18);
      font-weight: 600;
      font-family: $font-family-sans-serif;
      @include media-breakpoint-up(md) {
        margin-left: auto;
      }
      @include media-breakpoint-down(md) {
        margin-top: rem(35);
      }
    }
  }
  &__middle-row {
    --bs-gutter-x: #{rem(24)};
    --bs-gutter-y: #{rem(24)};

    margin-bottom: rem(20);
    @include media-breakpoint-up(xl) {
      margin-bottom: rem(40);
    }
    & > * {
      &:not(:last-child) {
        .sEventReg__item {
          @include media-breakpoint-only(md) {
            &:after {
              content: '';
              position: absolute;
              display: block;
              width: 1px;
              height: rem(65);
              top: 0;
              right: 0;
              background-color: rgba($color: #000000, $alpha: 0.3);
            }
          }
        }
      }
    }
  }
  &__item {
    position: relative;
    font-weight: 600;
    font-size: rem(16);
    line-height: 1.4;
    @include media-breakpoint-up(lg) {
      font-size: rem(18);
    }
    span {
      display: block;
      font-size: rem(14);
      color: rgba($color: #000000, $alpha: 0.5);
      margin-bottom: rem(4);
    }
  }
  &__bottom-row {
    --bs-gutter-x: #{rem(12)};
    --bs-gutter-y: #{rem(18)};

    @include media-breakpoint-up(xl) {
      --bs-gutter-y: #{rem(24)};
    }
  }
  &__btn {
    --bs-btn-padding-y: #{rem(16)};
    --bs-btn-font-size: #{rem(16)};
    --bs-btn-line-height: 1.21;
    --bs-btn-border-radius: #{rem(12)};
    --bs-btn-bg: #fff;
    // --bs-btn-hover-bg: #fff;
    // --bs-btn-active-bg: #fff;
    --bs-btn-disabled-bg: #fff;
    @include media-breakpoint-up(lg) {
      --bs-btn-padding-y: #{rem(18)};
      --bs-btn-font-size: #{rem(18)};
    }

    width: 100%;
  }
  &__end-time {
    font-size: rem(12);
    font-weight: 400;
    line-height: 1.85;
    @include media-breakpoint-up(md) {
      font-size: rem(14);
    }
    span {
      font-weight: 600;
      display: inline-block;
      margin-left: rem(17);
    }
  }
  &__bottom-btn {
    --bs-btn-padding-x: #{rem(11)};
    --bs-btn-padding-y: #{rem(11)};
    --bs-btn-font-size: #{rem(11)};
    --bs-btn-line-height: 1.21;
    --bs-btn-border-radius: #{rem(12)};
    --bs-btn-bg: #000;
    --bs-btn-disabled-bg: #000;
    @include media-breakpoint-only(md) {
      --bs-btn-padding-x: #{rem(16)};
      --bs-btn-padding-y: #{rem(16)};
    }
    svg {
      font-size: rem(20);
      width: 1em;
      height: 1em;
    }
  }
  &__bottom-icon {
    margin-right: rem(12);
  }
  &.sticky {
    @include media-breakpoint-up(lg) {
      position: fixed;
      // top: rem(162);
      top: rem(80);
      box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
      width: rem(290);
    }
    @include media-breakpoint-up(xl) {
      width: rem(350);
    }
    @include media-breakpoint-up(xxl) {
      width: rem(390);
    }
  }
}
