.main-wrapper {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-top: 95px;
  // min-height: calc(100vh - #{rem(150)});
}
body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &:before {
    content: '';
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba($color: #000000, $alpha: 0.6);
    z-index: 999;
    transition: all 0.36s ease-in-out;
    pointer-events: none;
    opacity: 0;
  }

  &.fixed {
    overflow: hidden;
    &:before {
      opacity: 1;
      pointer-events: all;
    }
  }
}

a {
  transition: all 0.36s ease-in-out;
}

label {
  width: 100%;
}

.btn {
  &-primary {
    --bs-btn-color: #000000;
    --bs-btn-hover-color: #fff;
    --bs-btn-active-color: #fff;
    --bs-btn-disabled-color: #fff;
  }
  // &-dark {
  //   --bs-btn-color: #fff;
  //   --bs-btn-bg: #000;
  //   --bs-btn-border-color: #000;

  //   --bs-btn-hover-color: #ffffff;
  //   --bs-btn-hover-bg: #{$primary};
  //   --bs-btn-hover-border-color: #{$primary};

  //   --bs-btn-active-color: #fff;
  //   --bs-btn-active-bg: #{$primary};
  //   --bs-btn-active-border-color: #{$primary};

  //   --bs-btn-disabled-color: #fff;
  //   --bs-btn-disabled-bg: #000;
  //   --bs-btn-disabled-border-color: #000;
  // }
  &-light {
    --bs-btn-color: #000;
    --bs-btn-bg: #ffffff;
    --bs-btn-border-color: #ffffff;

    --bs-btn-hover-color: #ffffff;
    --bs-btn-hover-bg: #000;
    --bs-btn-hover-border-color: #000;

    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #000;
    --bs-btn-active-border-color: #000;

    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #000;
    --bs-btn-disabled-border-color: #000;
  }
  &-outline-secondary {
    --bs-btn-color: #000;
    --bs-btn-bg: transparent;
    --bs-btn-border-color: #{$gray-500};

    --bs-btn-hover-color: #{$primary};
    --bs-btn-hover-bg: transparent;
    --bs-btn-hover-border-color: #{$primary};

    --bs-btn-active-color: #{$primary};
    --bs-btn-active-bg: transparent;
    --bs-btn-active-border-color: #{$primary};

    --bs-btn-disabled-color: #{$primary};
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #{$primary};
  }
}

.strike {
  text-decoration: line-through;
}
.price {
  .strike {
    opacity: 0.4;
    font-size: 60%;
  }
}


.hover-green:hover {
  color: $teal;
}

.carousel-control-prev-icon,.carousel-control-next-icon {
  background-image: none;
  svg {
    fill: rgba(#00abab, .6);
  }
  &:hover {
    svg {
      fill: #00abab;
    }
  }
}