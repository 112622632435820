.sRegStatus {
  padding-bottom: rem(24);
  padding-top: rem(24);
  @include media-breakpoint-up(md) {
    padding-bottom: rem(40);
    padding-top: rem(40);
  }
  @include media-breakpoint-up(lg) {
    padding-bottom: rem(60);
    padding-top: rem(60);
  }
  h3 {
    font-size: rem(30);
    margin-bottom: rem(32);
    letter-spacing: -0.23rem;
    @include media-breakpoint-up(md) {
      font-size: rem(40);
      margin-bottom: rem(40);
    }
    @include media-breakpoint-up(xl) {
      font-size: rem(56);
      margin-bottom: rem(60);
    }
  }
  &__text {
    font-size: rem(14);
    margin-bottom: rem(24);
    @include media-breakpoint-up(md) {
      font-size: rem(16);
      margin-bottom: rem(32);
    }
    @include media-breakpoint-up(xl) {
      font-size: rem(18);
      margin-bottom: rem(40);
    }
  }
  &__list {
    list-style-type: none;
    margin-bottom: 0;
    padding-left: 0;
  }
  &__list-item {
    padding: rem(24);
    border-radius: rem(12);
    background-color: #f8f8f8;
    display: flex;
    flex-direction: column;
    @include media-breakpoint-up(md) {
      padding: rem(32) rem(24);
      border-radius: rem(24);
      flex-direction: row;
    }
    @include media-breakpoint-up(xl) {
      padding: rem(40);
      border-radius: rem(32);
    }
    &:not(:last-child) {
      margin-bottom: rem(16);
      @include media-breakpoint-up(xl) {
        margin-bottom: rem(24);
      }
    }
    h4 {
      margin-bottom: rem(16);
      flex-grow: 1;
      font-size: rem(20);
      @include media-breakpoint-up(md) {
        // width: rem(288);
        margin-bottom: 0;
        font-size: rem(24);
      }
      @include media-breakpoint-up(xl) {
        max-width: rem(427);
        font-size: rem(32);
      }
    }
    ul {
      flex-grow: 1;
      padding-left: 0;
      margin-bottom: 0;
      list-style-type: none;
      @include media-breakpoint-up(md) {
        align-self: center;
        max-width: rem(258);
      }
      @include media-breakpoint-up(xxl) {
        max-width: rem(500);
      }
      li {
        font-size: rem(14);
        margin-bottom: rem(16);
        line-height: 1.37;
        position: relative;
        padding-left: rem(30);
        @include media-breakpoint-up(md) {
          padding-left: rem(50);
        }
        @include media-breakpoint-up(xl) {
          font-size: rem(16);
        }
        &:last-child {
          margin-bottom: 0;
        }
        &:before {
          content: '';
          position: absolute;
          display: block;
          width: rem(24);
          height: rem(19);
          background-image: url("data:image/svg+xml,%3Csvg width='34' height='27' viewBox='0 0 34 27' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.2216 26.5128L0 13.2912L6.64559 6.6456L13.2216 13.2216L26.4432 0L33.0888 6.6456L13.2216 26.5128Z' fill='%2300BFBF'/%3E%3C/svg%3E%0A");
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          @include media-breakpoint-up(md) {
            width: rem(34);
            height: rem(27);
          }
        }
      }
    }
  }
}
