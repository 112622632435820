.headerBlock {
  padding-top: rem(30);
  // padding-bottom: rem(109);
  margin-bottom: rem(-59);
  overflow: hidden;
  @include media-breakpoint-up(md) {
    padding-top: rem(70);
    // padding-bottom: rem(163);
    margin-bottom: rem(-48);
  }
  @include media-breakpoint-up(xxl) {
    padding-top: rem(81.4);
    // padding-bottom: rem(279);
    margin-bottom: rem(-177);
  }

  &__row {
    align-items: flex-end;
    margin-top: rem(50);
    margin-bottom: rem(51);

    @include media-breakpoint-up(md) {
      margin-top: rem(30);
      margin-bottom: rem(92);
    }
    @include media-breakpoint-up(xxl) {
      margin-top: rem(30);
      margin-bottom: rem(128);
    }
  }

  h1 {
    text-transform: uppercase;
    margin-bottom: 0;

    span {
      font-size: rem(14);
      display: block;
      max-width: max-content;
      @include media-breakpoint-up(md) {
        font-size: rem(24);
      }
      @include media-breakpoint-up(xxl) {
        font-size: rem(28);
      }
    }

    img {
      max-width: 100%;
      height: auto;
    }
  }

  &__btn {
    @include media-breakpoint-up(xxl) {
      --bs-btn-padding-y: #{rem(16.24)};
      --bs-btn-font-size: #{rem(18)};
      margin-bottom: rem(12.2);
    }

    width: 100%;
  }

  &__about_btn {
    width: 100%;
    @include  media-breakpoint-down(md){
      min-width: fit-content;
      width: 100%;
    }
  }

  &__wrap {
    position: relative;
  }

  --slideGutter: #{rem(16)};

  &__swiper {
    overflow: visible !important;
    margin-right: calc(var(--slideGutter) * -1) !important;
    margin-bottom: rem(60);
    @include media-breakpoint-up(md) {
      margin-bottom: rem(78);
    }
    @include media-breakpoint-up(xl) {
      margin-bottom: rem(41.2);
    }

    .swiper-wrapper {
      @include media-breakpoint-up(md) {
        flex-wrap: wrap;
        transform: none !important;
        margin-top: rem(-30);
      }
      @include media-breakpoint-up(xl) {
        margin-top: 0;
      }
    }
  }

  &__slide {
    display: flex !important;
    width: calc(68% - var(--slideGutter)) !important;
    margin-right: var(--slideGutter);
    @include media-breakpoint-up(md) {
      width: 100% !important;
      max-width: calc(100% / 2 - var(--slideGutter));
      margin-top: rem(30);
    }
    @include media-breakpoint-up(xl) {
      align-items: center;
      max-width: calc(100% / 3 - var(--slideGutter));
      margin-top: 0;
    }

    span {
      font-weight: 500;
      font-size: rem(14);
      max-width: rem(329);
      line-height: 1.22;
      @include media-breakpoint-up(lg) {
        font-size: rem(18);
      }
    }
  }

  &__img-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $gray-500;
    border-radius: 50%;
    flex: 0 0 auto;
    margin-right: rem(12);
    width: rem(48);
    height: rem(48);
    @include media-breakpoint-up(md) {
      margin-right: rem(16);
      width: rem(60);
      height: rem(60);
    }
    @include media-breakpoint-up(xxl) {
      margin-right: rem(30);
      width: rem(86);
      height: rem(86);
    }

    img {
      width: 100%;
      max-width: rem(28);
      @include media-breakpoint-up(md) {
        max-width: rem(32);
      }
      @include media-breakpoint-up(xxl) {
        max-width: rem(60);
      }
    }
  }

  &__footer-btn {
    width: 100%;
    margin-bottom: rem(50);
    @include media-breakpoint-up(md) {
      position: absolute;
      top: calc(50% + (25%));
      transform: translateY(-25%);
      width: calc(50% - var(--slideGutter));
      left: calc(50% + var(--slideGutter));
      margin-bottom: 0;
    }
    @include media-breakpoint-up(xl) {
      position: static;
      transform: translateY(0);
      width: 100%;
    }
  }

  &__benefits {
    position: relative;
    min-height: rem(316);
    border-radius: rem(20) rem(20) 0 0;
    overflow: hidden;
    padding: rem(50) 0 rem(109);
    @include media-breakpoint-down(md) {
      display: flex;
      align-items: flex-end;
    }
    @include media-breakpoint-up(md) {
      min-height: rem(370);
      border-radius: rem(40) rem(40) 0 0;
      padding: rem(83) 0 rem(163);
    }
    @include media-breakpoint-up(xxl) {
      min-height: rem(632);
      border-radius: rem(80) rem(80) 0 0;
      padding: rem(102.2) 0 rem(279);
    }

    &::after {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: -1;
      background-image: linear-gradient(
                      to right,
                      rgba(17, 0, 86, 0.14) 0%,
                      rgba(11, 0, 56, 0.55) 100%
      );
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -2;
    }
  }

  &__benefits-wrap {
    display: flex;
    align-items: center;
    @include media-breakpoint-up(md) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__benefit {
    font-family: 'ActayWide-Bold';
    font-weight: 700;
    color: #fff;
    font-size: rem(12);
    line-height: 0.8;
    @include media-breakpoint-up(xxl) {
      font-size: rem(22);
    }

    &:not(:last-child) {
      @include media-breakpoint-up(md) {
        margin-bottom: rem(18);
      }
      @include media-breakpoint-up(xxl) {
        margin-bottom: rem(40);
      }
    }

    span {
      display: block;
      font-size: rem(24);
      line-height: 1.23;
      @include media-breakpoint-up(md) {
        font-size: rem(30);
      }
      @include media-breakpoint-up(xxl) {
        font-size: rem(72);
      }
    }
  }
}

.about_bg {
  position: absolute;
  background-image: url('/img/about_bg.svg');
  top: 0;
  right: 0;
  width: 50vw;
  height: 500px;
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: -2;
}