.sContacts {
  padding-bottom: rem(48);
  padding-top: rem(24);
  @include media-breakpoint-down(sm) {
    margin-left: calc(var(--bs-gutter-x) * -1);
    margin-right: calc(var(--bs-gutter-x) * -1);
  }
  @include media-breakpoint-up(md) {
    padding-bottom: rem(80);
    padding-top: rem(40);
  }
  @include media-breakpoint-up(lg) {
    padding-bottom: rem(120);
    padding-top: rem(60);
  }
  h3 {
    margin-bottom: rem(32);
    @include media-breakpoint-down(sm) {
      margin-left: calc(var(--bs-gutter-x) * 1);
      margin-right: calc(var(--bs-gutter-x) * 1);
    }
    @include media-breakpoint-up(md) {
      margin-bottom: rem(40);
    }
    @include media-breakpoint-up(xl) {
      margin-bottom: rem(80);
    }
  }
  &__wrap {
    border: 1px solid $gray-500;
    background-color: #fff;
    border-radius: rem(20) rem(20) 0 0;
    margin-bottom: rem(-32);
    @include media-breakpoint-down(sm) {
      border-bottom: none;
    }
    @include media-breakpoint-up(md) {
      border-radius: rem(24) rem(24) 0 0;
    }
    @include media-breakpoint-up(lg) {
      border-radius: rem(40) rem(40) 0 0;
      margin-bottom: rem(-40);
    }
    &:last-child {
      border-radius: rem(20);
      @include media-breakpoint-down(sm) {
        border-radius: rem(20) rem(20) 0 0;
        .sContacts__row {
          padding-bottom: rem(32);
        }
      }
      @include media-breakpoint-up(md) {
        border-radius: rem(24);
      }
      @include media-breakpoint-up(lg) {
        border-radius: rem(40);
      }
    }
  }
  &__row {
    padding: rem(32) rem(20) rem(64) rem(20);
    @include media-breakpoint-up(md) {
      padding: rem(32) rem(32) rem(64) rem(32);
    }
    @include media-breakpoint-up(lg) {
      padding: rem(48) rem(48) rem(80) rem(48);
    }
    & > .row {
      --bs-gutter-y: #{rem(64)};
      @include media-breakpoint-up(xxl) {
        --bs-gutter-x: #{rem(128)};
      }
    }
  }
  &__col {
    position: relative;
    &:not(:last-child) {
      &:before {
        content: '';
        display: block;
        position: absolute;
        background-color: $gray-500;
        top: calc(100% + var(--bs-gutter-y) * 0.5);
        right: calc(var(--bs-gutter-x) / 2);
        width: calc(100% - var(--bs-gutter-x));
        height: 1px;
      }
      @include media-breakpoint-up(md) {
        &:before {
          top: 0;
          right: 0;
          width: 1px;
          height: 100%;
        }
      }
      @include media-breakpoint-up(lg) {
        &:before {
          top: calc(100% + var(--bs-gutter-y) * 0.5);
          right: calc(var(--bs-gutter-x) / 2);
          width: calc(100% - var(--bs-gutter-x));
          height: 1px;
        }
      }
      @include media-breakpoint-up(xl) {
        &:before {
          top: 0;
          right: 0;
          width: 1px;
          height: 100%;
        }
      }
    }
  }
  &__title {
    font-weight: 500;
    font-size: rem(14);
    margin-top: rem(32);
    padding-left: rem(20);
    padding-right: rem(20);
    @include media-breakpoint-up(md) {
      font-size: rem(16);
      margin-top: rem(40);
      padding-left: rem(32);
      padding-right: rem(32);
    }
    @include media-breakpoint-up(lg) {
      padding-left: rem(48);
      padding-right: rem(48);
    }
  }
  &__item {
    .h6 {
      font-weight: 700;
      font-size: rem(18);
      // letter-spacing: -4px;
      line-height: 1;
      margin-bottom: rem(16);
      @include media-breakpoint-up(md) {
        font-size: rem(20);
        margin-bottom: rem(20);
      }
      @include media-breakpoint-up(xxl) {
        font-size: rem(26);
      }
    }
    span {
      font-size: rem(14);
      text-transform: uppercase;
      color: #000;
      opacity: 0.6;
      line-height: 1;
      margin-bottom: rem(16);
      font-weight: 500;
      display: block;
      @include media-breakpoint-up(md) {
        font-size: rem(16);
        margin-bottom: rem(20);
      }
    }
    .row {
      --bs-gutter-x: #{rem(12)};
    }
  }
  &__tel {
    font-family: 'ActayWide-Bold';
    font-size: rem(18);
    line-height: 1;
    // letter-spacing: -4px;
    color: var(--telColor, #00bfbf);
    margin-bottom: rem(16);
    display: block;
    max-width: max-content;
    @include media-breakpoint-up(md) {
      font-size: rem(20);
      margin-bottom: rem(20);
    }
    @include media-breakpoint-up(lg) {
      font-size: rem(26);
    }
  }
  &__soc {
    width: rem(32);
    height: rem(32);
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: rem(12);
    @include media-breakpoint-up(md) {
      width: rem(40);
      height: rem(40);
    }
    &:hover {
      background-color: rgba($color: #000000, $alpha: 0.7);
    }
    svg {
      width: 1em;
      height: 1em;
      font-size: rem(18);
      @include media-breakpoint-up(md) {
        font-size: rem(20);
      }
    }
  }
}
