.event-head {
  padding-top: rem(40);
  padding-bottom: 0;
  margin-bottom: rem(-60);
  @include media-breakpoint-up(lg) {
    padding-top: rem(81);
    padding-bottom: rem(80);
    margin-bottom: 0;
  }
  &__container {
    position: relative;
    // z-index: -1;
  }
  &__bg {
    position: absolute;
    top: rem(220);
    right: rem(-140);
    width: rem(723);
    z-index: -1;
    color: var(--eventHeadBgImage, $primary);
    @include media-breakpoint-up(sm) {
      top: rem(310);
      right: rem(-140);
      width: rem(1313);
    }
    @include media-breakpoint-up(lg) {
      top: rem(-30);
      right: rem(-520);
      width: rem(1183);
    }
    @include media-breakpoint-up(xxl) {
      right: rem(-270);
    }
  }
  .row {
    --bs-gutter-y: #{rem(48)};
    @include media-breakpoint-up(md) {
      --bs-gutter-y: #{rem(65)};
    }
  }
  &__date {
    font-weight: 700;
    @include rfs(32px);
    color: var(--eventHeadDate, $primary);
    margin-bottom: rem(24);
  }
  h1 {
    font-weight: 700;
    line-height: calc(90 / 100);
    letter-spacing: -4%;
    font-size: rem(36);
    margin-bottom: rem(24);
    @include media-breakpoint-up(md) {
      margin-bottom: rem(40);
    }
    @include media-breakpoint-up(xl) {
      font-size: rem(70);
    }
    @include media-breakpoint-up(xxl) {
      font-size: rem(100);
    }
  }
  &__text {
    max-width: rem(750);
    font-weight: 600;
    font-size: rem(15);
    margin-bottom: rem(24);
    @include media-breakpoint-up(md) {
      font-size: rem(20);
    }
    @include media-breakpoint-up(xl) {
      font-size: rem(24);
      margin-bottom: rem(60);
    }
  }
  &__img-wrap {
    display: block;
    aspect-ratio: 1/1;
    @include media-breakpoint-down(sm) {
      margin-left: calc(var(--bs-gutter-x) * -1);
      margin-right: calc(var(--bs-gutter-x) * -1);
    }
    @include media-breakpoint-up(lg) {
      width: 100%;
      max-width: rem(663);
      margin-left: auto;
    }
    img {
      width: 100%;
      border-radius: rem(20) rem(20) 0 0;
      height: 100%;
      object-fit: cover;
      @include media-breakpoint-up(lg) {
        border-radius: rem(48);
      }
    }
  }
  .btn {
    --bs-btn-bg: #fff;

    font-weight: 500;
    font-size: rem(14);
    width: 100%;
    @include media-breakpoint-up(md) {
      max-width: rem(230);
    }
    svg {
      margin-right: rem(20);
      width: 1em;
      height: 1em;
      font-size: rem(25);
    }
  }
}
