.sOrganizers {
  padding-bottom: rem(24);
  padding-top: rem(24);
  @include media-breakpoint-up(md) {
    padding-bottom: rem(40);
    padding-top: rem(40);
  }
  @include media-breakpoint-up(lg) {
    padding-bottom: rem(60);
    padding-top: rem(60);
  }
  h3 {
    margin-bottom: rem(32);
    @include media-breakpoint-up(md) {
      margin-bottom: rem(40);
    }
    @include media-breakpoint-up(xl) {
      margin-bottom: rem(80);
    }
  }
  .row {
    --bs-gutter-x: #{rem(23)};
    --bs-gutter-y: #{rem(23)};
  }
  &__item {
    transition: all 0.36s ease-in-out;
    text-align: center;
    filter: grayscale(1);
    &:hover {
      filter: grayscale(0);
    }
    span {
      display: block;
      font-size: rem(10);
      font-weight: 400;
      margin-top: rem(8);
      @include media-breakpoint-up(md) {
        font-size: rem(11);
      }
      @include media-breakpoint-up(lg) {
        font-size: rem(14);
      }
    }
  }
  &__img-wrap {
    aspect-ratio: 230/118;
    border-radius: rem(16);
    border: 1px solid $gray-500;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: rem(230);
    img {
      max-width: 100%;
      height: auto;
    }
  }
}
