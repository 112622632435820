.footer {
  background-color: #fff;
  border-radius: rem(20) rem(20) 0 0;
  padding-top: rem(40);
  position: relative;
  z-index: 1;
  @include media-breakpoint-up(md) {
    border-radius: rem(40) rem(40) 0 0;
    padding-top: rem(60);
  }
  @include media-breakpoint-up(xl) {
    padding-top: rem(100);
    border-radius: rem(80) rem(80) 0 0;
  }
  &__row {
    --bs-gutter-y: #{rem(40)};
    @include media-breakpoint-up(md) {
      --bs-gutter-y: #{rem(50)};
    }
  }
  &__logo {
    display: block;
    width: 100%;
    max-width: rem(202);
    @include media-breakpoint-up(lg) {
      max-width: rem(142);
    }
    @include media-breakpoint-up(xxl) {
      max-width: rem(242);
    }
    img {
      width: 100%;
    }
  }
  ul {
    // display: flex;
    // flex-direction: column;
    --bs-gutter-y: #{rem(24)};
    list-style-type: none;
    margin-bottom: 0;
    padding-left: 0;
    columns: 2;
    // margin-top: calc(var(--bs-gutter-y) * -1);
    @include media-breakpoint-up(xxl) {
      margin-right: rem(100);
    }
    @include media-breakpoint-up(xl) {
      --bs-gutter-y: #{rem(40)};
    }
    @include media-breakpoint-up(md) {
      columns: 1;
    }
    li {
      // margin-top: calc(var(--bs-gutter-y) * 1);
      &:not(:last-child) {
        margin-bottom: var(--bs-gutter-y);
      }
    }
    a {
      font-size: rem(14);
      font-weight: 400;
      line-height: 1.21;
      @include media-breakpoint-up(md) {
        font-size: rem(16);
      }
      @include media-breakpoint-up(xxl) {
        font-size: rem(18);
      }
    }
  }
  &__btnsGroup {
    display: flex;
    border: 1px solid $gray-500;
    border-radius: rem(12);
    margin-bottom: rem(40);
    max-width: max-content;
    @include media-breakpoint-up(md) {
      margin-bottom: rem(35);
    }
    @include media-breakpoint-up(lg) {
      border-radius: rem(16);
    }
    @include media-breakpoint-up(xl) {
      margin-bottom: rem(65);
    }
    button {
      --bs-btn-font-size: #{rem(16)};
      --bs-btn-color: #000;
      --bs-btn-hover-color: #{$primary};
      --bs-btn-hover-bg: #fff;
      --bs-btn-active-color: #000;
      --bs-btn-active-bg: #fff;
      --bs-btn-disabled-color: #000;
      --bs-btn-disabled-bg: transparent;
      border: none;
      padding-top: rem(8);
      padding-bottom: rem(8);
      padding-left: rem(6);
      padding-right: rem(6);
      @include media-breakpoint-up(lg) {
        --bs-btn-font-size: rem(16);
        padding-top: rem(12);
        padding-bottom: rem(12);
      }
      @include media-breakpoint-up(xxl) {
        padding-top: rem(13);
        padding-bottom: rem(13);
      }
      &:hover {
        svg {
          path[fill='black'] {
            fill: $primary;
          }
        }
      }
      &:first-child {
        padding-left: rem(20);
        @include media-breakpoint-up(xxl) {
          padding-left: rem(40);
        }
      }
      &:last-child {
        padding-right: rem(20);
        @include media-breakpoint-up(xxl) {
          padding-right: rem(40);
        }
      }
      svg {
        font-size: rem(24);
        width: 1em;
        height: 1em;
        path {
          transition: all 0.36s ease-in-out;
        }
      }
    }
  }
  &__tel {
    display: block;
    max-width: max-content;
    font-size: rem(14);
    font-weight: 400;
    margin-bottom: rem(4);
    line-height: 1.21;
    @include media-breakpoint-up(md) {
      font-size: rem(16);
      margin-bottom: rem(34);
    }
    @include media-breakpoint-up(xxl) {
      font-size: rem(18);
    }
  }
  &__email {
    display: block;
    max-width: max-content;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.21;
    @include media-breakpoint-up(md) {
      font-size: 16px;
      margin-bottom: 34px;
    }
    @include media-breakpoint-up(xxl) {
      font-size: rem(18);
    }
  }
  .form-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: rem(16);
    @include media-breakpoint-up(sm) {
      align-items: flex-end;
    }
    @include media-breakpoint-up(md) {
      margin-bottom: rem(24);
      flex-direction: row;
    }
    @include media-breakpoint-up(xxl) {
      max-width: rem(513);
      margin: 0 auto rem(24);
    }
    form {
      display: flex;
      width: 100%;
      flex-direction: column;
      @include media-breakpoint-up(md) {
        align-items: flex-end;
        flex-direction: row;
      }
    }
    &__input-wrap {
      flex-grow: 1;
      @include media-breakpoint-up(md) {
        margin-right: rem(8);
      }
    }
    &__title {
      font-weight: 400;
      line-height: 1.16;
      font-size: rem(14);
      margin-bottom: rem(16);
      display: block;
      @include media-breakpoint-up(md) {
        font-size: rem(18);
        margin-bottom: rem(24);
      }
    }
    &__input {
      background-color: $gray-200;
      font-size: rem(14);
      padding: rem(7.56) rem(12);
      border-radius: rem(12);
      line-height: 1.92;
      border: none;
      margin-bottom: rem(16);
      @include media-breakpoint-up(md) {
        font-size: rem(16);
        padding: rem(10.5) rem(20);
        border-radius: rem(16);
        line-height: 1.68;
        margin-bottom: 0;
      }
      &:focus {
        box-shadow: none;
      }
      &::placeholder {
        color: #000;
      }
    }
    &__btn {
      --bs-btn-padding-y: 9.5px;
      --bs-btn-font-size: 14px;
      margin-bottom: 16px;
      @include media-breakpoint-up(md) {
        --bs-btn-padding-x: 19px;
        --bs-btn-padding-y: 11px;
        --bs-btn-font-size: 16px;

        margin-bottom: 0;
      }
    }
  }
  &__subtext {
    font-weight: 400;
    line-height: 1.38;
    font-size: 10px;
    color: rgba($color: #000000, $alpha: 0.5);
    @include media-breakpoint-up(xxl) {
      max-width: 513px;
      margin: 0 auto;
    }
    @include media-breakpoint-up(md) {
      font-size: 14px;
    }
  }
  &__social {
    color: #000;
    &:hover {
      color: $primary;
    }
  }
  &__bottom-row {
    margin-top: 60px;
    border-top: 1px solid $gray-500;
    padding: 40px 0;
    .row {
      --bs-gutter-y: 32px;

      align-items: center;
    }
    p {
      margin-bottom: 0;
      font-size: 12px;
      font-weight: 400;
      display: block;
      width: 100%;
      @include media-breakpoint-up(lg) {
        font-size: 14px;
        width: auto;
      }
    }
    a {
      font-size: 12px;
      font-weight: 400;
      @include media-breakpoint-up(lg) {
        font-size: 14px;
      }
    }
  }
  &__bottom-col {
    @include media-breakpoint-up(md) {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      max-width: calc(550px + var(--bs-gutter-x));
      margin-right: auto;
    }
    @include media-breakpoint-up(xl) {
      max-width: calc(680px + var(--bs-gutter-x));
    }
    @include media-breakpoint-up(xxl) {
      max-width: calc(880px + var(--bs-gutter-x));
    }
  }
  &__company {
    font-size: 12px;
    font-weight: 400;
    vertical-align: middle;
    img {
      width: 100%;
      max-width: 122px;
      margin-left: 12px;
      @include media-breakpoint-up(md) {
        margin-top: 8px;
        display: block;
        margin-left: auto;
      }
      @include media-breakpoint-up(lg) {
        display: inline;
        margin-left: 12px;
        margin-top: 0;
      }
    }
  }
}
