.sSimilarEvents {
  background-color: $primary;
  padding-top: rem(60);
  padding-bottom: rem(74);
  border-radius: rem(20) rem(20) 0 0;
  margin-bottom: rem(-20);
  position: relative;
  z-index: 1;
  @include media-breakpoint-up(md) {
    padding-top: rem(100);
    padding-bottom: rem(175);
    border-radius: rem(40) rem(40) 0 0;
    margin-bottom: rem(-75);
  }
  @include media-breakpoint-up(lg) {
    padding-top: rem(150);
    padding-bottom: rem(220);
    border-radius: rem(80) rem(80) 0 0;
    margin-bottom: rem(-82);
  }
  .swiper {
    --swiperCount: 1;
    --swiperGutter: #{rem(32)};
    margin-right: calc(var(--swiperGutter) * -1) !important;
    overflow: visible;
    @include media-breakpoint-up(sm) {
      overflow: hidden;
    }
    @include media-breakpoint-up(md) {
      --swiperCount: 2;
      --swiperGutter: #{rem(40)};
    }
    @include media-breakpoint-up(xl) {
      --swiperCount: 3;
      --swiperGutter: #{rem(59)};
    }
  }
  .swiper-slide {
    width: calc(82% / var(--swiperCount) - var(--swiperGutter)) !important;
    @include media-breakpoint-up(sm) {
      width: calc(100% / var(--swiperCount) - var(--swiperGutter)) !important;
    }
    margin-right: var(--swiperGutter) !important;
  }
  &__container {
    position: relative;
    z-index: 1;
    .bg {
      position: absolute;
      top: rem(-136);
      right: rem(-354);
      width: 100%;
      max-width: rem(845);
      z-index: -1;
      display: none;
      @include media-breakpoint-up(lg) {
        display: block;
      }
    }
  }
  h2 {
    line-height: 1.1;
    margin-bottom: rem(36);
    font-size: rem(36);
    letter-spacing: -1px;
    @include media-breakpoint-up(md) {
      margin-bottom: rem(80);
      font-size: rem(48);
    }
    @include media-breakpoint-up(lg) {
      font-size: rem(80);
      margin-bottom: rem(100);
    }
  }
}
