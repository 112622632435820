.sProgram {
  padding-bottom: rem(24);
  @include media-breakpoint-up(md) {
    padding-bottom: rem(40);
  }
  @include media-breakpoint-up(lg) {
    padding-bottom: rem(60);
  }
  @include media-breakpoint-down(sm) {
    margin-left: calc(var(--bs-gutter-x) * -1);
    margin-right: calc(var(--bs-gutter-x) * -1);
  }
  h3 {
    margin-top: rem(15);
    margin-bottom: rem(32);
    @include media-breakpoint-down(sm) {
      margin-left: calc(var(--bs-gutter-x) * 1);
      margin-right: calc(var(--bs-gutter-x) * 1);
    }
    @include media-breakpoint-up(md) {
      margin-bottom: rem(40);
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: rem(80);
    }
  }
  &__head {
    padding: rem(24) rem(20) rem(48) rem(20);
    border-radius: rem(24) rem(24) 0 0;
    background-color: $primary;
    @include media-breakpoint-up(md) {
      padding: rem(40) rem(40) rem(80) rem(40);
    }
    @include media-breakpoint-up(lg) {
      border-radius: rem(40) rem(40) 0 0;
      padding: rem(48) rem(48) rem(96) rem(48);
    }
    .row {
      --bs-gutter-y: #{rem(20)};
    }
    h5 {
      margin-bottom: 0;
      line-height: 1.17;
    }
    iframe {
      aspect-ratio: 617/356;
      width: 100%;
      height: auto;
      max-width: rem(617);
      margin-top: rem(24);
      border-radius: rem(8);
      @include media-breakpoint-up(md) {
        margin-top: rem(32);
        border-radius: rem(12);
      }
    }
  }
  &__left-col {
    max-width: max-content;
    @include media-breakpoint-up(xl) {
      max-width: rem(180);
    }
    @include media-breakpoint-up(xxl) {
      max-width: rem(260);
    }
  }
  &__live {
    background-color: #fff;
    border-radius: rem(12);
    padding: rem(12);
    display: flex;
    align-items: center;
    font-family: 'ActayWide-Bold';
    font-size: rem(16);
    line-height: 1;
    @include media-breakpoint-up(md) {
      border-radius: rem(16);
      font-size: rem(16);
    }
    @include media-breakpoint-up(xl) {
      max-width: rem(200);
      font-size: rem(14);
    }
    @include media-breakpoint-up(xxl) {
      font-size: rem(18);
    }
    &:before {
      content: '';
      display: block;
      width: rem(28);
      height: rem(28);
      background-image: url("data:image/svg+xml,%3Csvg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='14' cy='14' r='11.5' stroke='%23BF009F'/%3E%3Ccircle cx='14' cy='14' r='6.5' fill='%23BF009F' stroke='%23BF009F'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      margin-right: rem(8);
      flex: 0 0 auto;
      @include media-breakpoint-up(xxl) {
        margin-right: rem(16);
      }
    }
  }
  &__body {
    position: relative;
    margin-top: rem(-24);
    @include media-breakpoint-up(md) {
      margin-top: rem(-40);
    }
    @include media-breakpoint-up(lg) {
      margin-top: rem(-48);
    }
  }
  &__item {
    background-color: #fff;
    padding: rem(24) rem(20) rem(48) rem(20);
    border-radius: rem(24) rem(24) 0 0;
    border: 1px solid $gray-500;
    &:not(:first-child) {
      margin-top: rem(-24);
      @include media-breakpoint-up(md) {
        margin-top: rem(-40);
      }
      @include media-breakpoint-up(lg) {
        margin-top: rem(-48);
      }
    }
    &:last-child {
      border-radius: rem(24);
      @include media-breakpoint-up(lg) {
        border-radius: rem(40);
      }
    }
    @include media-breakpoint-up(md) {
      padding: rem(40) rem(40) rem(80) rem(40);
    }
    @include media-breakpoint-up(lg) {
      border-radius: rem(40) rem(40) 0 0;
      padding: rem(48) rem(48) rem(96) rem(48);
    }
    .row {
      --bs-gutter-y: #{rem(12)};
    }
    h5 {
      margin-bottom: 0;
    }
    p {
      margin-top: rem(20);
      font-size: rem(14);
      font-weight: 500;
      // margin-bottom: rem(24);
      margin-bottom: 0;
      @include media-breakpoint-up(md) {
        font-size: rem(16);
        // margin-bottom: rem(36);
      }
    }
  }
  &__time {
    font-family: 'ActayWide-Bold';
    color: var(--telColor, $primary);
    font-weight: 700;
    font-size: rem(20);
    @include media-breakpoint-up(md) {
      font-size: rem(24);
    }
    @include media-breakpoint-up(lg) {
      font-size: rem(18);
      margin-top: rem(4.8);
    }
  }
  &__person-row {
    --bs-guuter-x: #{rem(36)};
    --bs-guuter-y: #{rem(24)};

    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: rem(24);
    margin-top: rem(24);
    @include media-breakpoint-down(sm){
      grid-template-columns: 1fr ;
    }
    @include media-breakpoint-up(md) {
      margin-top: rem(36);
      margin-bottom: rem(36);
      grid-template-columns: 1fr 1fr 1fr 1fr ;
    }
  }
  &__person {
    display: flex;
    width: max-content;
    box-sizing: border-box;
    .sProgram__name {
      display: grid;
    }
    @include media-breakpoint-up(md) {
      flex-direction: column;
    }
  }
  &__img-wrap {
    flex: 0 0 auto;
    width: 100%;
    max-width: rem(80);
    border-radius: rem(12);
    margin-right: rem(20);
    overflow: hidden;
    @include media-breakpoint-up(md) {
      margin-right: 0;
      margin-bottom: rem(20);
      max-width: rem(100);
      border-radius: rem(20);
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  &__name {
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: rem(8);
    font-size: rem(14);
    @include media-breakpoint-up(md) {
      font-size: rem(16);
    }
  }
  &__status {
    width: 100px;
    font-weight: 400;
    line-height: 1.3;
    margin-bottom: rem(8);
    font-size: rem(11);
    text-overflow: ellipsis;
    text-wrap: nowrap;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    user-select: none;
    cursor: default;
    @include media-breakpoint-down(sm){
      width: 100%;
    }
    @include media-breakpoint-up(md) {
      font-size: rem(12);
    }
  }
  &__place {
    font-size: rem(11);
    font-weight: 600;
    @include media-breakpoint-up(md) {
      font-size: rem(12);
    }
  }
  &__avatars {
    --bs-gutter-x: #{rem(4)};
    --bs-gutter-y: #{rem(4)};
    img {
      width: 100%;
      max-width: rem(32);
      border-radius: rem(8);
      object-fit: cover;
      height: auto;
      @include media-breakpoint-up(md) {
        max-width: rem(40);
        border-radius: rem(8);
      }
    }
  }
  &__more {
    width: rem(32);
    height: rem(32);
    border-radius: rem(8);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: rem(12);
    color: #000;
    background-color: $primary;
    @include media-breakpoint-up(md) {
      width: rem(40);
      height: rem(40);
      border-radius: rem(8);
      font-size: rem(16);
    }
  }
  &__control {
    --bs-gutter-x: #{rem(12)};
    --bs-gutter-y: #{rem(24)};

    margin-bottom: rem(40);
    @include media-breakpoint-down(sm) {
      margin-left: calc(var(--bs-gutter-x) * 1);
      margin-right: calc(var(--bs-gutter-x) * 1);
    }
    @include media-breakpoint-up(md) {
      margin-bottom: rem(100);
    }
    @include media-breakpoint-up(xl) {
      margin-bottom: rem(120);
    }
  }
  &__slider {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    justify-content: flex-start;
    @include media-breakpoint-down(md) {
      justify-content: space-evenly;
    }
    .swiper-wrapper{
      display: none;
    }
    @include media-breakpoint-down(xl) {
      overflow: visible;
    }
  }
  &__slide {
    width: auto !important;
    row-gap: 10px;
    .btn {
      --bs-btn-padding-x: #{rem(23)};
      --bs-btn-padding-y: #{rem(8)};
      --bs-btn-font-weight: 600;
      --bs-btn-font-size: #{rem(14)};
      --bs-btn-border-radius: 12px;

      --bs-btn-hover-border-color: #000;
      --bs-btn-hover-color: #fff;
      --bs-btn-hover-bg: #000;
      --bs-btn-active-border-color: #000;
      --bs-btn-active-color: #fff;
      --bs-btn-active-bg: #000;

      position: relative;
      @include media-breakpoint-up(md) {
        --bs-btn-border-radius: 16px;
        --bs-btn-padding-y: #{rem(12)};
      }
      @include media-breakpoint-up(lg) {
        --bs-btn-padding-y: #{rem(17)};
        --bs-btn-font-size: #{rem(16)};
      }
      &.active {
        background-color: #000;
        border-color: #000;
        color: #fff;
      }
     
    }
  }
  &__date {
    //display: flex;
    align-items: center;
    //justify-content: space-between;
    border: 1px solid $gray-500;
    border-radius: rem(16);
    //max-width: rem(300);
    //position: relative;
    overflow: hidden;
    a {
      //display: block;
      font-size: rem(16);
      font-weight: 600;
      width: 100%;
      min-width: rem(160);
      text-align: center;
    }
    .swiper {
      padding: rem(16) rem(50);
      .swiper-wrapper {
        .swiper-slide {
          //width: 60px !important;
        }
      }
    }
    .swiper-slide {
      text-align: center;
    }
    .swiper-slide-active {
      color: $teal;
      a {
        color: $teal;
      }
    }
    .swiper-button-prev,
    .swiper-button-next {
      //width: rem(60);
      background-repeat: no-repeat;
      background-size: 40px 40px;
      background-position: center;
      background-color: white;

      position: absolute;
      top: 50px;
      transform: translateY(-50%);
      z-index: 100;
      height: 100%;
      cursor: pointer;
      &:hover {
        opacity: 0.6;
      }
      &:after {
        content: unset;
      }
      &.swiper-button-disabled {
        pointer-events: none;
        opacity: 0.2;
      }
    }
    .swiper-button-prev {
      background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M23 14L17 20L23 26' stroke='black' stroke-width='2'/%3E%3C/svg%3E%0A");
      left: rem(0);
    }
    .swiper-button-next {
      background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 14L24 20L18 26' stroke='black' stroke-width='2'/%3E%3C/svg%3E%0A");
      right: rem(0);
    }
  }
}

.sticky-section {
  position: sticky;
  top: 190px;
  z-index: -1;
  background: white; /* Убедитесь, что фон установлен, чтобы избежать перекрытия элементов */
}