.sContent {
  //padding-top: rem(48);
  padding-bottom: rem(2);
  background-color: #fff;
  @include media-breakpoint-up(md) {
    padding-bottom: rem(0);
  }
  @include media-breakpoint-up(xl) {
    padding-bottom: rem(0);
  }
  @include media-breakpoint-down(sm) {
    margin-left: calc(var(--bs-gutter-x) * -1);
    margin-right: calc(var(--bs-gutter-x) * -1);
    padding-left: calc(var(--bs-gutter-x) * 1);
    padding-right: calc(var(--bs-gutter-x) * 1);
    border-radius: rem(20) rem(20) 0 0;
  }
  img {
    max-width: 100%;
    height: auto;
    border-radius: 12px;
  }
  p {
    font-weight: 500;
    line-height: 1.77;
    font-size: rem(14);
    @include media-breakpoint-up(sm) {
      font-size: rem(16);
    }
    @include media-breakpoint-up(lg) {
      font-size: rem(18);
    }
  }
  h3 {
    font-weight: 700;
    margin-bottom: rem(32);
    @include media-breakpoint-up(sm) {
      margin-bottom: rem(48);
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: rem(60);
    }
  }
  ul {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: rem(24);
    @include media-breakpoint-up(lg) {
      margin-bottom: rem(40);
    }
    li {
      padding-left: rem(22);
      position: relative;
      font-size: rem(14);
      @include media-breakpoint-up(sm) {
        font-size: rem(16);
      }
      @include media-breakpoint-up(lg) {
        font-size: rem(18);
      }
      &:not(:last-child) {
        margin-bottom: rem(16);
      }
      &:before {
        content: '';
        position: absolute;
        display: block;
        width: rem(6);
        height: rem(6);
        border-radius: 50%;
        background-color: $primary;
        left: 0;
        top: rem(9);
      }
    }
  }
}
