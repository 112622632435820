.sEventList {
  background-color: #fff;
  border-radius: rem(20) rem(20) 0 0;
  padding: rem(60) 0 rem(40);
  @include media-breakpoint-up(md) {
    border-radius: rem(40) rem(40) 0 0;
    padding: rem(80) 0 rem(60);
  }
  @include media-breakpoint-up(xxl) {
    border-radius: rem(80) rem(80) 0 0;
    padding: rem(176.4) 0 rem(145);
  }
  h2 {
    font-size: rem(36);
    margin-bottom: rem(40);
    letter-spacing: -0.23rem;
    @include media-breakpoint-up(md) {
      font-size: rem(50);
      margin-bottom: rem(60);
    }
    @include media-breakpoint-up(xxl) {
      font-size: rem(90);
      margin-bottom: rem(70);
    }
  }
  &__row {
    --bs-gutter-y: #{rem(40)};
    @include media-breakpoint-up(md) {
      --bs-gutter-y: #{rem(60)};
    }
    @include media-breakpoint-up(xxl) {
      --bs-gutter-y: #{rem(79)};
    }
  }
  &__btn {
    @include media-breakpoint-down(xxl) {
      display: block;
      width: 100%;
      max-width: rem(304);
      margin: 0 auto;
    }
    @include media-breakpoint-up(xxl) {
      --bs-btn-padding-y: #{rem(15.5)};
      --bs-btn-font-size: 1.125rem;
      min-width: rem(467);
    }
  }
  &__inner-row {
    --bs-gutter-y: #{rem(60)};
    @include media-breakpoint-up(md) {
      --bs-gutter-x: #{rem(40)};
    }
    @include media-breakpoint-up(xxl) {
      --bs-gutter-y: #{rem(120)};
      --bs-gutter-x: #{rem(60)};
    }
  }
  &__col {
    &--horizontal {
      .event-card {
        @include media-breakpoint-up(xl) {
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
          gap: rem(59);
          justify-content: space-between;
        }
        &__img-wrap {
          aspect-ratio: 994/450;
          max-width: rem(994);
          @include media-breakpoint-up(xl) {
            margin-bottom: 0;
          }
          .event-card__date {
            display: none;
          }
        }
        &__content {
          .event-card__date {
            display: block;
          }
          .h4 {
            font-size: rem(24);
            @include media-breakpoint-up(md) {
              font-size: rem(32);
            }
            @include media-breakpoint-up(xxl) {
              font-size: rem(42);
            }
          }
        }
      }
    }
    &:nth-child(8n + 4) {
      & + .sEventList__col--horizontal {
        .event-card {
          @include media-breakpoint-up(xl) {
            flex-direction: row;
          }
        }
      }
    }
  }
  &__swiper {
    overflow: visible !important;
    @include media-breakpoint-up(xxl) {
      overflow: hidden !important;
    }
    .swiper-slide {
      width: auto;
    }
  }
  &__link {
    font-weight: 600;
    line-height: 1.68;
    border: 1px solid $gray-500;
    color: #000;
    display: block;
    font-size: rem(14);
    padding: rem(9) rem(23);
    border-radius: rem(12);
    @include media-breakpoint-up(xxl) {
      font-size: rem(16);
      padding: rem(15) rem(23);
      border-radius: rem(16);
    }
    &.active,
    &:hover {
      border-color: #000;
      color: #000;
    }
  }
}
