.sMobileApp {
  padding-top: rem(60);
  padding-bottom: rem(319);
  overflow: hidden;
  @include media-breakpoint-up(md) {
    padding-top: rem(60);
    padding-bottom: rem(111);
  }
  @include media-breakpoint-up(xxl) {
    padding-top: rem(106.4);
    padding-bottom: rem(203.8);
  }
  &__container {
    position: relative;
  }
  &__bg {
    width: 100%;
    height: auto;
    position: absolute;
    max-width: rem(218);
    bottom: rem(-290);
    right: -30px;
    @include media-breakpoint-up(md) {
      bottom: unset;
      top: -33px;
      right: -144px;
      max-width: rem(311);
    }
    @include media-breakpoint-up(xxl) {
      top: -90px;
      right: 126px;
      max-width: rem(495);
    }
  }
  &__svg-bg {
    position: absolute;
    bottom: rem(-428);
    right: rem(-188);
    width: rem(723);
    z-index: -1;
    color: var(--eventHeadBgImage, $primary);
    @include media-breakpoint-up(sm) {
      // top: rem(310);
      right: rem(-140);
    }
    @include media-breakpoint-up(md) {
      bottom: unset;
      top: rem(-166);
      right: rem(-278);
      width: rem(747);
    }
    @include media-breakpoint-up(xxl) {
      right: rem(-270);
      width: rem(1183);
    }
  }
  h2 {
    font-size: rem(36);
    margin-bottom: rem(30);
    line-height: 1;
    letter-spacing: -0.23rem;
    @include media-breakpoint-up(md) {
      font-size: rem(50);
      margin-bottom: rem(54);
    }
    @include media-breakpoint-up(xxl) {
      font-size: rem(90);
      margin-bottom: rem(60);
    }
  }
  p {
    display: flex;
    font-size: rem(14);
    margin-bottom: rem(27);
    line-height: 1.6;
    @include media-breakpoint-up(md) {
      font-size: rem(16);
      margin-bottom: rem(50);
    }
    @include media-breakpoint-up(xxl) {
      font-size: rem(18);
      margin-bottom: rem(90);
    }
    &:before {
      content: '';
      flex: 0 0 auto;
      display: none;
      @include media-breakpoint-up(md) {
        display: block;
        width: rem(72);
        height: rem(72);
        margin-right: rem(32);
      }
      @include media-breakpoint-up(xxl) {
        width: rem(122);
        height: rem(122);
        margin-right: rem(124);
      }

      background-image: url("data:image/svg+xml,%3Csvg width='122' height='122' viewBox='0 0 122 122' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='60.979' cy='60.979' r='60.229' stroke='%2300BFBF' stroke-width='1.5'/%3E%3Cpath d='M80.5547 60.4211L50.3449 77.8627L50.3449 42.9795L80.5547 60.4211Z' fill='%2300BFBF'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
  }
  &__link-wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: rem(8);
    grid-row-gap: rem(8);
    @include media-breakpoint-up(md) {
      grid-template-columns: repeat(3, 1fr);
    }
    @include media-breakpoint-up(xl) {
      grid-column-gap: rem(20);
      grid-row-gap: rem(20);
    }
  }
  &__link {
    border: 1px solid $gray-500;
    border-radius: rem(8);
    display: flex;
    align-items: center;
    // padding: rem(11.4) rem(24);
    // font-size: rem(16);
    background-color: #fff;
    padding: rem(6) rem(13);
    font-size: rem(9);
    @include media-breakpoint-up(xxl) {
      padding: rem(11.4) rem(24);
      font-size: rem(16);
      border-radius: rem(16);
    }
    &:nth-child(3) {
      padding: rem(12);
      grid-area: 3 / 1 / 4 / 2;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      @include media-breakpoint-up(md) {
        grid-area: 1 / 3 / 3 / 4;
        max-width: rem(140);
      }
      svg {
        margin-right: 0;
        margin-bottom: rem(10);
        font-size: rem(43);
        @include media-breakpoint-up(lg) {
          font-size: rem(70);
        }
      }
    }
    svg {
      width: 1em;
      height: 1em;
      font-size: rem(22);
      flex: 0 0 auto;
      margin-right: rem(16);
      @include media-breakpoint-up(lg) {
        font-size: rem(36);
      }
    }
  }
}
