.form-wrap {
  &__row {
    --bs-gutter-y: #{rem(24)};
  }
  &__input-wrap {
    position: relative;
  }
  &__title {
    font-size: rem(16);
    line-height: 1.2;
    margin-bottom: rem(8);
    // font-weight: 600;
    display: inline-block;
  }
  &__caption {
    font-size: 10px;
  }
  &__input {
    font-size: rem(16);
    font-weight: 400;
    padding: rem(17) rem(19);
    border-radius: rem(16);
    border-color: $gray-200;
    background-color: $gray-200;
    &:focus {
      background-color: $gray-200;
      border-color: $primary;
      box-shadow: none;
    }
    &.is-invalid,
    &.is-invalid:focus {
      box-shadow: none;
      border-color: #bf009f;
    }
    &:has(~ .form-wrap__pass) {
      padding-right: rem(60);
    }
    &-wrap {
      .invalid-feedback {
        font-size: rem(12);
      }
    }
  }
  &__submit {
    --bs-btn-border-radius: #{rem(12)};

    width: 100%;
    @include media-breakpoint-up(lg) {
      --bs-btn-padding-y: #{rem(15.5)};
      --bs-btn-font-size: #{rem(18)};
    }
  }
  &__pass {
    position: absolute;
    right: 0;
    bottom: 0;

    --bs-btn-bg: transparent;
    --bs-btn-border-color: transparent;
    --bs-btn-hover-bg: transparent;
    --bs-btn-hover-border-color: transparent;
    --bs-btn-active-bg: transparent;
    --bs-btn-active-border-color: transparent;

    &:hover,
    &.active {
      svg {
        opacity: 0.6;
      }
    }
    svg {
      transition: all 0.36s ease-in-out;
    }
  }
  &__add-new {
    transition: all 0.36s ease-in-out;
    display: flex;
    align-items: center;
    font-size: rem(14);
    justify-content: center;
    border-style: dashed;
    font-weight: 400;
    cursor: pointer;
    padding: rem(17) rem(24);
    &:hover {
      &::before {
        background-color: #fff;
      }
      // color: rgba($color: #000000, $alpha: 0.6);
    }
    &:before {
      content: "";
      flex: 0 0 auto;
      margin-right: rem(24);
      width: rem(24);
      height: rem(24);
      border-radius: 50%;
      transition: all 0.36s ease-in-out;
      background-color: $primary;
      background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 13L7 1' stroke='black' stroke-width='1.2' stroke-linecap='round'/%3E%3Cpath d='M13 7.01367L1 7.01367' stroke='black' stroke-width='1.2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
      background-size: 14px;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  &__caption {
    font-weight: 500;
    font-size: rem(10);
    color: rgba($color: #000000, $alpha: 0.5);
    margin-top: rem(8);
  }
}

.form-check {
  padding-left: rem(38);
  cursor: pointer;
  max-width: max-content;
  margin-bottom: 0;
  .form-check-input {
    margin-left: rem(-38);
    width: rem(24);
    height: rem(24);
    border-color: $gray-500;
    cursor: pointer;
    margin-top: 0;
    &:checked {
      background-color: $white;
      border-color: $gray-500;
      &[type="checkbox"] {
        --bs-form-check-bg-image: url("data:image/svg+xml,%3Csvg width='15' height='12' viewBox='0 0 15 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.707 8.293L1.414 5L0 6.414L4.707 11.121L14.414 1.414L13 0L4.707 8.293Z' fill='%2300BFBF'/%3E%3C/svg%3E%0A");

        background-size: 14px 12px;
      }
    }
    &:focus {
      box-shadow: none;
    }
  }
  .form-check-label {
    font-size: rem(14);
    color: rgba($color: #000000, $alpha: 0.5);
    a {
      color: $primary;
    }
  }
}
