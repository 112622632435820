.pageContent {
  background-color: #fff;
  border-radius: rem(20) rem(20) 0 0;
  padding: rem(60) 0 rem(40);
  @include media-breakpoint-up(md) {
    border-radius: rem(40) rem(40) 0 0;
    padding: rem(80) 0 rem(60);
  }
  @include media-breakpoint-up(xxl) {
    border-radius: rem(80) rem(80) 0 0;
    padding: rem(176.4) 0 rem(145);
  }
  h2 {
    font-size: rem(36);
    margin-bottom: rem(40);
    letter-spacing: -0.23rem;
    @include media-breakpoint-up(md) {
      font-size: rem(50);
      margin-bottom: rem(60);
    }
    @include media-breakpoint-up(xxl) {
      font-size: rem(90);
      margin-bottom: rem(70);
    }
  }
}
